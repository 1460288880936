import React, {useEffect, useState, useContext} from 'react'
import Main from '../components/Main'
import Footer from '../components/Footer'
import Header from '../components/Header'
import { useHistory } from 'react-router-dom'
import { UI_COLOR_BUTTON } from '../config'

import { Button, Modal, notification, Input, Form, Select } from 'antd'

import {
  REST_CUSTOMER_UPDATE_PROFILE,
  REST_DELETE_ME,
  REST_CUSTOMER_PROFILE,
  REST_GET_ALLCOUNTRIES,
  requestToServer,
  clearLocalData,
  getLocalData
} from '../lib/helper'

import { GeneralContext } from '../lib/context'

export default function Profile() {

  const urlParams = new URLSearchParams(window.location.search)
  let redirect = urlParams.get('r')

  const [state, dispatch] = useContext(GeneralContext)

  let history = useHistory()

  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [governDocId, setgovernDocId] = useState('')
  const [phone, setPhone] = useState('')
  const [countryId, setCountryId] = useState('')
  const [cityId, setCityId] = useState(null)

  const [showDialog, setShowDialog] = useState(false)

  const [countries, setCountries] = useState([])
  const [cities, setCities] = useState([])

  useEffect( () => {
    if ( getLocalData() ) {
      requestToServer({},REST_GET_ALLCOUNTRIES).then( ({countries}) => setCountries(countries) )
      requestToServer({},REST_CUSTOMER_PROFILE).then( ({profile}) => dispatch({type: 'SET_PROFILE', payload: profile}) )
    } else { history.push('/') }
  }, [])

  useEffect( () => {
    if (state.profile) {
      if ( state.profile.first_name ) { setFirstName(state.profile.first_name) }
      if ( state.profile.last_name ) { setLastName(state.profile.last_name) }
      if ( state.profile.govern_doc_id ) { setgovernDocId(state.profile.govern_doc_id) }
      if ( state.profile.cell_phone ) { setPhone(state.profile.cell_phone) }
      if ( state.profile.countryId ) { setCountryId(state.profile.countryId) }
      if ( state.profile.cityId ) { setCityId(state.profile.cityId) }
    }
  }, [state.profile] )


  useEffect( () => {
    let selectedCountry = countries.find(item => item.id === countryId )
    if ( selectedCountry ) {
      if (selectedCountry.cities.length > 0 ) {
        setCities(selectedCountry.cities)
        if ( state.profile && state.profile.countryId === countryId && state.profile.cityId ) {
          setCityId(state.profile.cityId)
        } else {
          setCityId(selectedCountry.cities[0]['id'])
        }
      } else {
        setCities([])
        setCityId(null)
      }
    }
  }, [countryId, countries])


  const updateProfile = () => {
    requestToServer({firstName, lastName, governDocId, phone, countryId, cityId },REST_CUSTOMER_UPDATE_PROFILE).then( s => {
      notification.success({
        message: 'Perfil actualizado'
      })
      dispatch({type: 'SET_PROFILE', payload: { cell_phone: phone,
        cityId,
        countryId,
        first_name: firstName,
        govern_doc_id: governDocId,
        last_name: lastName } })
      if ( redirect ) { history.push(redirect) }
    })
  }

  const deleteAccount = () => {
    requestToServer({},REST_DELETE_ME).then( () => {
      notification.success({
        message: 'Cuenta eliminada'
      })
      clearLocalData()
      history.push('/')
      dispatch({type: 'CLEAR_STATE'})
    })
  }

  let localData = getLocalData()


  return (
    <Main>
      <Header title='Mi Perfil' backLink='/' />
      <Form style={{paddingLeft:'15px', paddingRight:'15px', maxWidth:'400px', margin:'0 auto', paddingBottom:'20px' }}>
        <Form.Item label="Nombres ">
          <Input  value={firstName} onChange={(e)=>setFirstName(e.target.value)}/>
        </Form.Item>
        <Form.Item label="Apellidos ">
          <Input value={lastName} onChange={(e)=>setLastName(e.target.value)} />
        </Form.Item>
        <Form.Item label="Documento de Identidad ">
          <Input value={governDocId} onChange={(e)=>setgovernDocId(e.target.value)} />
        </Form.Item>
        <Form.Item label="Teléfono ">
          <Input value={phone} onChange={(e)=>setPhone(e.target.value)} type='tel' />
        </Form.Item>
        <Form.Item label="Email ">
          <Input value={localData.user.email} />
        </Form.Item>
        
                
        { countries.length > 0 && <div>
          <Form.Item label="Pais ">
            <Select style={{width:'180px'}} value={countryId} onChange={(e)=> setCountryId(e)}>
              { countries.map(item=>{ return(
                <Select.Option value={item.id} key={'country' + item.id}>{item.name}</Select.Option>
              )}) }
            </Select>
          </Form.Item>

          { cities.length > 0 && 
            <Form.Item label="Ciudad ">
              <Select style={{width:'180px'}} value={cityId} onChange={(e)=>setCityId(e)}>
                { cities.map(item=>{ return(
                  <Select.Option value={item.id} key={'city' + item.id}>{item.name}</Select.Option>
                )}) }
              </Select>
            </Form.Item>
          }

        </div>}

        <div style={{marginBottom:'20px', textAlign:'center'}}>
          <Button onClick={()=>updateProfile()} style={{backgroundColor:UI_COLOR_BUTTON, color:'white'}} size='large' className='standard-button'>Actualizar</Button></div>
        <div style={{marginBottom:'20px', textAlign:'center'}}>
          <Button onClick={()=>setShowDialog(true)} style={{backgroundColor:'red', color:'white'}} size='large' className='standard-button'>Eliminar cuenta</Button></div>
      </Form>
      <Footer/>

      <Modal
        title="Eliminar cuenta"
        visible={showDialog}
        onOk={()=>deleteAccount()}
        onCancel={()=> setShowDialog(false)}
        cancelText="Cancelar"
        okText="Eliminar cuenta"
      >
        <p>Está seguro de eliminar su cuenta?</p>
        <p>Este proceso es irreversible, perderá todos los puntos acumulado hasta el momento.</p>
      </Modal>


    </Main>
  )
}