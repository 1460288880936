import React, {useEffect, useState, useContext} from 'react'
import Main from '../components/Main'
import Header from '../components/Header'
import Footer from '../components/Footer'
import { useHistory } from 'react-router-dom'
import { useParams } from 'react-router-dom'

import { isMultiStore, UI_COLOR_BUTTON } from '../config'
import {
  REST_GET_GENERAL_STORE_INFO,
  REST_BECOME_CUSTOMER,
  REST_REQUEST_REWARD,
  REST_CUSTOMER_GET_ALL_POINTS,
  REST_GET_STORE_INFO,
  getUrlHashtag,
  requestToServer,
  getLocalData
} from '../lib/helper'

import { GeneralContext } from '../lib/context'

import { Tabs, List, Row, Col, Modal, notification, Button, Tag } from 'antd'
import { CheckOutlined, GiftOutlined, WhatsAppOutlined, PhoneOutlined, MailOutlined, ShareAltOutlined, MinusOutlined } from '@ant-design/icons'

import {
  WhatsappShareButton,
  WhatsappIcon,
  TelegramShareButton,
  TelegramIcon,
  EmailShareButton,
  EmailIcon,
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon
} from 'react-share'

export default function Store() {

  const [state, dispatch] = useContext(GeneralContext)

  const { storeId } = useParams()
  const history = useHistory()

  const [storeData, setstoreData] = useState(null)
  const [storeInfo, setStoreInfo] = useState(null)

  const [reward, setReward] = useState(null) // to use in modal

  const [referModal, setReferModal] = useState(false)
  const [referPoints, setReferPoints] = useState(null)

  const TheReferModal = ({referModal, referPoints, storeInfo}) => {

    let link = window.location.href + '#' + storeInfo.customerReferCode
    let title = storeInfo ? 'Acumula puntos y gana premios en ' + storeInfo.name : ''

    return (<Modal
      visible={referModal}
      title='Refiere y Gana !'
      onCancel={()=>setReferModal(false)}
      footer={null}
    >
      <p>Refiere a tus familiares y/o amistades { referPoints && storeInfo && <span>, por el primer consumo en <strong>{storeInfo.name}</strong> de cada persona referida ganarás <strong>{referPoints}</strong> puntos</span>} : </p>

      <p style={{textAlign:'center', paddingTop:'5px'}}>
        <WhatsappShareButton
          url={link}
          title={title}
          separator=" :: "
        ><WhatsappIcon size={40} round /></WhatsappShareButton>
        &nbsp;
        <TelegramShareButton
          url={link}
          title={title}>
          <TelegramIcon size={40} round/>
        </TelegramShareButton>
        &nbsp;
        <EmailShareButton
          url={link}
          subject='Gaba pubros'
          body={title}
        >
          <EmailIcon size={40} round/>
        </EmailShareButton>
        &nbsp;
        <FacebookShareButton
          url={link}
          quote={title}
        >
          <FacebookIcon size={40} round/>
        </FacebookShareButton>
        &nbsp;
        <TwitterShareButton
          url={link}
          title={title}
        >
          <TwitterIcon size={40} round/>
        </TwitterShareButton>
      </p>
      <p style={{paddingTop:'20px'}}>O copia el siguiente enlace y hazles llegar a tus familiares y amigos:</p>
      <p style={{textAlign:'center'}}><Tag
        onClick={() => {
          navigator.clipboard.writeText(link)
          notification.success({message:'Enlace copiado'})
        }}
      >{link}</Tag></p>
      <p style={{textAlign:'center', paddingTop:'5px'}}><Button type='primary' style={{backgroundColor:UI_COLOR_BUTTON}}
        onClick={() => {
          navigator.clipboard.writeText(link)
          notification.success({message:'Enlace copiado'})
        }}
      >copiar enlace</Button></p>
    </Modal>)
  }

  function updateHistory ({storeId}) { // gets all info for store
    if ( getLocalData() ) {
      requestToServer({storeId},REST_GET_STORE_INFO).then( result =>{
        if ( result.s === 'ok' ) {
          setStoreInfo(result.store)
          if (result.store.refer) { setReferPoints(result.store.referPoints)}
        }
      })
    } else { // for not logged
      requestToServer({storeId},REST_GET_GENERAL_STORE_INFO).then( result =>{
        if ( result.s === 'ok' ) {
          setStoreInfo(result.store)
        }
      })
    }
  }


  useEffect(() => {

    if ( state.userStores.length > 0 ) {
      let store = state.userStores.find(item => item.id.toString() === storeId.toString() )
      setstoreData(store) // to reuse in /comerces
      updateHistory({storeId})
    } else { // accesing directly to the page
      if ( getLocalData() ) {
        updateAllPoints()
      } else {
        updateHistory ({storeId}) // for anonymous users, to get just storeInfo
      }
    }

    

  }, [storeId])


  let currentPoints = storeData ? storeData.purchaseAmount - storeData.redeemedAmount : 0

  const updateAllPoints = () => {
    requestToServer({},REST_CUSTOMER_GET_ALL_POINTS).then( result =>{
      if ( result.s === 'ok' ) {
        dispatch({
          type: 'SET_USER_STORES',
          payload: result.storePoints
        })
        let store = result.storePoints.find(item => item.id.toString() === storeId.toString() )
        setstoreData(store)
        updateHistory({storeId})

      }
    })
  }

  const requestReward = ({reward}) => {
    setReward(null)
    requestToServer({rewardstuffId: reward.id, storeId},REST_REQUEST_REWARD).then( result =>{
      if ( result.s === 'ok' ) {
        updateAllPoints()
        notification.success({
          message: 'Solicitud realizada',
          description: 'En breve recibirá un email con la confirmación.'
        })
      } else {
        notification.error({
          message: 'No puede solicitar un canje',
          description: result.m
        })
      }
    })
  }

  let backLink = isMultiStore ? '/comercios' : '/'
  let phonefullNumber = storeInfo && storeInfo.phone ? 'tel:+' + storeInfo.country.phoneCode + storeInfo.phone : null
  let whatsappUrl = storeInfo && storeInfo.phone ? 'https://wa.me/' + storeInfo.country.phoneCode + storeInfo.phone : null
  let localData = getLocalData()
  if (!localData ) { backLink = '/' }

  return (
    <>
      { storeData ?
        <Main>

          <Header title='Comercio' backLink={backLink} />

          <Row justify="center" style={{paddingBottom:'20px', lineHeight:'100%', textAlign: storeData.logo ? 'left' : 'center'}}>
            
            { storeData.logo && <Col>
              <div style={{paddingRight:'5px'}}>
                <img src={storeData.logo} style={{width:'70px', height:'70px', borderRadius:'5px'}}/>
              </div>
            </Col>}
            <Col>
              <div style={{paddingTop:'3px'}}>
                <div style={{fontSize:'1.6em', fontWeight:'bold'}}>{ storeData.purchaseAmount - storeData.redeemedAmount }</div>
                <div style={{fontSize:'0.8em'}}>puntos</div>

                <div style={{fontSize:'1.1em', fontWeight:'bold', paddingTop:'8px'}}>{ storeData.name }</div>
                <div style={{fontSize:'0.9em'}}>{ storeData.storeType}</div>
              </div>
            </Col>
            { storeInfo && storeInfo.refer && <Col>
              <div style={{paddingLeft:'20px'}}>
                <ShareAltOutlined style={{fontSize:'40px', color: UI_COLOR_BUTTON}} onClick={()=>setReferModal(true)}/>
              </div>
              <TheReferModal referModal={referModal} referPoints={referPoints} storeInfo={storeInfo} />
            </Col>}

          </Row>


          <Tabs defaultActiveKey="1" type="card" size='large'>

            <Tabs.TabPane tab="Recompensas" key="1">
              <List
                dataSource={storeData.rewardstuffs.sort((a, b) => (a.points > b.points) ? 1 : -1)}
                locale={{emptyText: 'No hay recompensas activas'}}
                renderItem={item => (
                  <List.Item key={item.id} style={{cursor:'pointer'}} onClick={()=>{}}>
                    <Col span={14} style={{paddingLeft:'15px'}}>
                      <div style={{paddingRight:'15px'}}>
                        <div style={{fontSize:'1.2em', fontWeight:'bold'}}>{item.name}</div>
                        <div>
                          { item.description && item.description.split('\n').map((line, i) => (
                              <p key={i}>
                                  {line}
                              </p>
                          ))}
                        </div>
                        <div className='clickable'
                          style={{ backgroundColor: item.points - currentPoints < 0 ? UI_COLOR_BUTTON : 'white', color: item.points - currentPoints < 0 ? 'white' : 'black',
                            borderStyle:'solid', borderWidth: '1px', borderColor: UI_COLOR_BUTTON,
                            display:'inline-block', paddingLeft:'10px', paddingRight:'10px', borderRadius:'5px', marginTop:'5px', marginBottom:'5px',
                            paddingTop: '1px', paddingBottom: '1px'
                          }}
                          onClick={()=>{
                            let leftPoints = item.points - currentPoints
                            if ( leftPoints < 0 ) {
                              if ( storeInfo && storeInfo.requestReward ) { 
                                setReward(item)
                              } else {
                                let message = storeInfo && storeInfo.rewardRequestInfo ? storeInfo.rewardRequestInfo : 'Este premio no está disponible para el canje'
                                notification.error({message})
                              }
                            } else {
                              notification.error({
                                message:'Aún no puedes canjear este premio',
                                description: 'Te faltan ' + leftPoints + ' puntos'
                              })
                            }
                          }}
                        >Puntos : <span style={{fontSize:'1.3em', fontWeight:'bold'}}>{item.points}</span></div>
                        <div style={{fontSize:'0.8em'}}>
                          { currentPoints >= item.points ?
                            <div style={{color:'blue'}}>Ya puedes canjear este premio</div> : <div style={{color:'green'}}>Aún te faltan {item.points - currentPoints} puntos para este premio</div>}
                        </div>
                      </div>
                    </Col>

                    <Col span={10} style={{paddingRight:'15px', textAlign:'right'}}>
                    {item.image && <img src={item.image} style={{width:'150px', height:'auto', maxWidth:'100%'}}/>}
                    </Col>
                  </List.Item>
                )}
              />
            </Tabs.TabPane>

            <Tabs.TabPane tab="Historial" key="2" onTabClick={()=>{}}>

              {storeInfo && <List
                  dataSource={storeInfo.history}
                  renderItem={item => {
                    let pointsText = ''
                    let colorPoints = 'black'
                    let leftIcon = null
                    if ( item.type === 'purchase' ) {
                      pointsText = item.points > 0 ? '+' + item.points : item.points
                      colorPoints = item.points > 0 ? 'green' : 'red'
                      leftIcon = item.points > 0 ? <CheckOutlined style={{fontSize:'30px', color:colorPoints}}/> : <MinusOutlined style={{fontSize:'30px', color:colorPoints}}/>
                    } else {
                      pointsText = '-' + item.points
                      colorPoints = 'blue'
                      leftIcon = <GiftOutlined style={{fontSize:'30px', color:'blue'}}/>
                    }

                    return (
                    <List.Item key={item.createdAt} style={{paddingLeft:'15px', paddingRight:'15px'}}>
                      <Col span={4}>
                        { leftIcon }
                      </Col>
                      <Col span={14}>
                        <div>{item.dateTime}</div>
                        <div>
                          { item.type === 'purchase' ?
                            <>
                              { item.cDescription ? <div>{item.cDescription}</div> : <div>Puntos acumulados</div> }
                            </>
                          :
                            <>
                              <div>{item.jsonInfo.rewardstuffName}</div>
                              { item.status && item.status === 'requested' && <div style={{color:'red', fontSize:'0.8em'}}>Canje solicitado</div> }
                            </>
                          }
                        </div>
                      </Col>
                      <Col span={6} style={{textAlign:'center', fontSize:'1.3em', fontWeight:'bold'}}>
                        <div style={{color:colorPoints}}>{pointsText}</div>
                      </Col>

                    </List.Item>
                  )
                }}
                />
 
              }            
            </Tabs.TabPane>

            { storeInfo && <Tabs.TabPane tab="Info" key="3">
              <div style={{paddingLeft:'15px', paddingRight:'15px'}}>
                { storeInfo.logo && <div style={{textAlign:'center'}}><img src={storeInfo.logo} with='200' height='200'/></div>}
                <h2 style={{textAlign:'center'}}>{storeInfo.name}</h2>
                { storeInfo.description && <div>
                  { storeInfo.description.split('\n').map((line, i) => (
                      <p key={i}>
                          {line}
                      </p>
                  ))}
                </div> }

                { storeInfo.phone &&
                  <div style={{textAlign:'center', fontSize:'1.2em', marginTop:'20px'}}>
                    <a href={phonefullNumber}><PhoneOutlined style={{paddingRight:'5px'}}/>+{storeInfo.country.phoneCode} {storeInfo.phone}</a>
                    { storeInfo.whatsapp && <a href={whatsappUrl}><WhatsAppOutlined style={{color:'#075E54', paddingLeft:'20px'}}/> Whatsapp</a>}
                  </div>
                }
                { storeInfo.email && <div style={{textAlign:'center', fontSize:'1.2em', marginTop:'20px'}}><a href={'mailto:' + storeInfo.email}><MailOutlined /> {storeInfo.email}</a></div> }

                { storeInfo.locations && storeInfo.locations.length > 0 && <div style={{paddingTop:'10px'}}>
                  <h3>{storeInfo.locations.length === 1 ? 'Ubicación:' : 'Establecimientos:'}</h3>
                  {storeInfo.locations.map(item => {
                    return(<a key={'location' + item.id} style={{border:'1px solid #cccccc', borderRadius:'5px', padding:'5px', display:'block', marginBottom:'10px'}}
                        href={'https://www.google.com/maps/dir/?api=1&destination=' + item.geo.coordinates[1] + '%2C' + item.geo.coordinates[0] + '&zoom=' + item.zoom } target='_blank'
                      >
                      <span style={{fontWeight:'bold', display:'block'}}>{ item.city ? item.city.name : item.otherCity }</span>
                      <span style={{display:'block'}}>{item.address}</span>
                    </a>)
                  })}
                </div>}

              </div>
            </Tabs.TabPane>}

          </Tabs>

          <Footer />

          <Modal
            visible={reward}
            onCancel={()=>setReward(false)}
            onOk={()=>requestReward({reward})}
            destroyOnClose={true}
            okText={'CONFIRMAR'}
            cancelText={'Cerrar'}
            title='Canjear Recompensa'
          >
            {reward && <div>Desea pedir el canje del premio <strong>{reward.name}</strong>?, se descontará <strong>{reward.points}</strong> puntos de tu balance.</div>}
          </Modal>

        </Main>

      :

        <Main>
          
            <>{/* for logged uesrs*/}
              <Header title='Comercio' backLink={backLink} />

              { storeInfo && <div style={{paddingLeft:'15px', paddingRight:'15px'}}>
                { storeInfo.logo && <div style={{textAlign:'center'}}><img src={storeInfo.logo} with='200' height='200'/></div>}
                <h1 style={{textAlign:'center'}}>{storeInfo.name}</h1>
                { storeInfo.description && <div>
                  { storeInfo.description.split('\n').map((line, i) => (
                      <p key={i}>
                          {line}
                      </p>
                  ))}
                </div> }
                <div style={{marginTop:'20px', textAlign:'center'}}>
                  <p>Aún no eres cliente de este Comercio.</p>
                  { storeInfo.welcomePoints && storeInfo.welcomePoints > 0 && <p>Este Comercio entrega <strong>{storeInfo.welcomePoints}</strong> puntos a nuevos clientes.</p> }
                  <a style={{display:'block'}}>
                    <Button size='large' style={{backgroundColor:UI_COLOR_BUTTON, color:'white', borderRadius:'5px'}}
                      onClick={()=>{

                        let hashTag = getUrlHashtag()

                        if ( localData ) {
                          requestToServer({storeId, hashTag},REST_BECOME_CUSTOMER).then( result => {
                            if (result.s === 'ok') {
 
                              // removing hashTag from url
                              var uri = window.location.toString();
                               if (uri.indexOf("#") > 0) {
                                  var clean_uri = uri.substring(0,
                                                  uri.indexOf("#"));
                   
                                  window.history.replaceState({},
                                          document.title, clean_uri);

                              }

                              window.location.reload()

                            } else { notification.error({message:result.m})}
                          })
                        } else { // for anonymus
                          let redirectPath = '/?redirect=/comercio/' + storeId
                          if (hashTag) { redirectPath += '#' + hashTag }
                          history.push( redirectPath )
                        }
                      }}
                    >{ localData ? 'Ser Cliente' : 'Ingresa o Regístrate'}</Button>
                  </a>
                </div>
              </div>}
              <Footer />
            </>

          
        </Main>
      }
    </>
  )
}