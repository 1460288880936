export const isMultiStore = false
export const platformName = 'fidellify'
export const clienAppName = 'Fidellify'

export const loginPageLogo = require( './assets/logoFidellify.png' ).default
export const versionName = '4.2'

//for one store
export const clienStoreId = 1

// for PROD
export const API_SERVER = 'https://fidellify.api.pretii.lat'

// for DEV
//export const API_SERVER = 'http://192.168.1.20:8080'


export const UI_COLOR_BUTTON = '#00bbff'
export const UI_LOGIN_FOOTER_OBJ = {
  text: 'by Global Commerce',
  link: null
}


export const enableGA = false

export const APP_ANDROID = "https://play.google.com/store/apps/details?id=com.fidellify.customer"
export const APP_IOS     = null
